export default {
  english: {
    signIn: 'Sign In',
    email: 'E-mail',
    password: 'Password',
    badCredentials: 'Bad credentials',
    unverified: "Please activate your account to log in. Check your inbox (and spam folder) for our activation email. Didn't get it?",
    requestActivationCode: 'Request a new link',
    activationEmailSent: 'Activation email sent',
    reset: 'Reset password',
    termsText: 'By signing in, you accept our',
    terms: 'terms of use.',
    termsUrl: '/en/terms-of-service',
    noAccountYet: 'Don\'t have an account yet?',
    signUp: 'Sign Up',
    mandatoryFields: 'Fields marked with * are mandatory',
    errors: {
      invalidEmail: 'Email field should be a valid email address',
    },
  },
  french: {
    signIn: 'Connexion',
    email: 'E-mail',
    password: 'Mot de passe',
    badCredentials: 'Mauvais identifiants',
    unverified: "Veuillez activer votre compte pour vous connecter. Vérifiez votre boîte de réception (et les spams) pour trouver notre email d'activation. Vous ne l'avez pas reçu ?",
    requestActivationCode: 'Demandez un nouveau lien',
    activationEmailSent: "Email d'activation envoyé",
    reset: 'Réinitialiser le mot de passe',
    termsText: 'En vous connectant, vous acceptez nos',
    terms: 'conditions d\'utilisation.',
    termsUrl: '/fr/conditions-generales-d-utilisation',
    noAccountYet: 'Vous n\'avez pas encore de compte ?',
    signUp: 'Inscription',
    mandatoryFields: 'Les champs marqués d\'un * sont obligatoires',
    errors: {
      invalidEmail: 'Le champ e-mail doit être une adresse e-mail valide',
    },
  },
  arabic: {
    signIn: 'الدخول',
    email: 'البريد الإلكتروني',
    password: 'كلمة مرور',
    badCredentials: 'وقع خطأ',
    unverified: 'يرجى تفعيل حسابك لتسجيل الدخول. تحقق من صندوق الوارد (ومجلد الرسائل غير المرغوب فيها) لرسالة التفعيل الخاصة بنا. لم تصلك الرسالة؟',
    requestActivationCode: 'اطلب رابطًا جديدًا',
    activationEmailSent: 'تم إرسال بريد التفعيل',
    reset: 'مسح كلمة المرور',
    termsText: 'بتسجيل الدخول ، فأنت تقبل',
    terms: 'شروط الاستخدام.',
    termsUrl: '/ar/شروط-الخدمة',
    noAccountYet: 'ليس لك حساب بعد',
    signUp: 'الخروج',
    mandatoryFields: 'الحقول التي تحمل علامة * إلزامية',
    errors: {
      invalidEmail: 'يجب أن يكون حقل البريد الإلكتروني عنوان بريد إلكتروني صالح',
    },
  },
  hebrew: {
    signIn: 'התחבר',
    email: 'דואר אלקטרוני',
    password: 'סיסמה',
    badCredentials: 'משתמש וסיסמה לא נכונים',
    unverified: 'אנא הפעל את החשבון שלך כדי להתחבר. בדוק את תיבת הדואר אלקטרוני (ותיבת הספאם) למייל האימות שלנו. לא קיבלת את המייל?',
    requestActivationCode: 'בקש קישור חדש',
    activationEmailSent: 'אימייל הפעלה נשלח',
    reset: 'אפס סיסמה',
    termsText: 'בהתחברות אתה מסכים ל',
    terms: 'תנאי שימוש שלנו',
    termsUrl: '/he/terms-of-service',
    noAccountYet: 'עדיין אין ברשותך מנוי?',
    signUp: 'הירשם',
    mandatoryFields: 'שדות המסומנים ב-* הם חובה',
    errors: {
      invalidEmail: 'כתובת האימייל לא תקינה',
    },
  },
}
